<template>
  <div class="bullet-inline-list" :style="{ fontSize: size / 16 + 'rem' }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BulletInlineList',
  props: {
    size: {
      type: Number,
      default: 16
    }
  }
}
</script>

<style lang="scss">
.bullet-inline-list {
  & > a,
  & > span {
    line-height: 1;

    &:not(:first-child) {
      @include icon('e923');

      &:before {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        font-size: 4px;
        padding: 0 rem(8px) 2px rem(8px);
      }
    }
  }

  & > a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $blue-900;
    }
  }
}
</style>
