<template>
  <button
    v-if="userIsLoggedIn"
    :class="{
      ['type-' + type]: true,
      'is-saved': job.saved
    }"
    @click="$emit('toggle', !job.saved)"
  >
    <span v-if="type === 'detail'" class="text">
      {{ job.saved ? 'Saved' : 'Save job' }}
    </span>

    <span>
      <img
        v-if="job.saved"
        src="~/assets/components/jobs/saved.svg"
        alt="saved icon"
      />
      <img v-else src="~/assets/components/jobs/save.png" alt="save icon" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    job: {
      required: true,
      type: Object
    },
    type: {
      type: String,
      default: 'list'
    }
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  position: relative;
  z-index: 11;
  display: flex;
  align-items: center;
  color: $gray-700;

  &.type-list {
    flex-direction: column;
    font-size: 14px;

    .text {
      opacity: 0;
      transition: opacity 0.3s;
      height: 0;
    }

    &.is-saved .text {
      opacity: 1;
    }
  }

  &.type-detail {
    font-size: 16px;

    .text {
      padding-right: rem(10px);
    }
  }

  img {
    height: 32px;
  }
}
</style>
