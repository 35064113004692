<template>
  <div class="newsletter-link">
    <b-button v-if="!showUpdate" variant="link" @click.prevent="show = true">
      <img src="~/assets/img/icons/alarm.svg" class="mr-10" alt="Job alert bell" />
      Get an email with new jobs in this&nbsp;search
    </b-button>
    <b-button v-else variant="link" @click.prevent="onSubmit">
      <img src="~/assets/img/icons/alarm.svg" class="mr-10" alt="Job alert bell" />
      Get an email with new jobs in this&nbsp;search
    </b-button>

    <b-modal
      :id="modalId"
      v-model="show"
      centered
      modal-class="modal-bb-squared newsletter-modal"
      hide-footer
    >
      <template slot="default">
        <div>
          <h4 class="mb-20">Leave your email address</h4>
          <p>
            Leave your email address to receive email updates of this&nbsp;search
          </p>

          <form class="mt-20" @submit.prevent="onSubmit">
            <AlertSlideUpDown :show="!!promptErrorMessage">{{
              promptErrorMessage
            }}</AlertSlideUpDown>

            <FormItem
              invalid-message="Please enter your email address"
              :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
              required
            >
              <b-form-input
                v-model="form.email"
                :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                placeholder="Email"
              />
            </FormItem>

            <div class="mt-10">
              <button type="submit" class="btn btn-lg w-100">
                <span v-if="!isRequesting">Subscribe</span>
                <b-spinner v-else small />
              </button>
            </div>
          </form>
        </div>
      </template>
    </b-modal>

    <b-modal
      :id="modalId"
      v-model="showSuccess"
      centered
      modal-class="modal-bb-squared newsletter-modal"
      hide-footer
    >
      <template slot="default">
        <div>
          <h4 class="mb-20">You’ve got mail!</h4>
          <p>
            Check your inbox and confirm your subscription to start receiving emails about
            new&nbsp;jobs
          </p>
        </div>

        <button class="btn mt-30" @click.prevent="showSuccess = false">
          Close
        </button>
      </template>
    </b-modal>

    <b-modal
      v-model="showUpdateSuccess"
      centered
      modal-class="modal-bb-squared newsletter-modal"
      hide-footer
    >
      <template slot="default">
        <div>
          <h4 class="mb-20">Your preferences was updated</h4>
          <p>
            We updated your existing subscription of digest email. Visit “Email notifications” under
            your settings for the full overview of your job criterias.
          </p>
        </div>

        <button class="btn mt-30" @click.prevent="showUpdateSuccess = false">
          Close
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import FormItem from '@/components/form/FormItem';
import AlertSlideUpDown from '@/components/AlertSlideUpDown';
import ApplicantProfileModel from '@/models/ApplicantProfile';

export default {
  name: 'JobsNewsletter',
  components: {
    FormItem,
    AlertSlideUpDown
  },
  mixins: [validationMixin],
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      show: false,
      showSuccess: false,
      showUpdateSuccess: false,
      promptErrorMessage: undefined,
      isRequesting: false,
      form: {
        email: undefined
      },
      applicantProfile: undefined
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    userEmail() {
      return this.$store.state.user.data.email;
    },
    userCurrentProfile() {
      return this.$store.getters['user/currentProfile'];
    },
    siteCountries() {
      return this.$store.getters['meta/siteCountries'];
    },
    modalId() {
      return 'modal-' + this._uid;
    },
    showUpdate() {
      return this.isLoggedIn && this.userEmail && this.userCurrentProfile.type === 'applicant';
    }
  },
  watch: {
    show() {
      this.isRequesting = false;
      this.form.email = undefined;
      this.$v.$reset();
    }
  },
  async mounted() {
    try {
      if (this.isLoggedIn && this.userEmail && this.userCurrentProfile.type === 'applicant') {
        const { data } = await this.$axios.get('digest/mail/' + this.userEmail);
        this.applicantProfile = new ApplicantProfileModel(data);
        this.form.email = this.applicantProfile.email;
      }
    } catch (err) {
      console.log('err', err);
    }
  },
  methods: {
    onInput() {
      this.error = '';
    },
    async onSubmit() {
      // console.log('submit');
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      if (this.isRequesting) {
        return;
      }

      this.isRequesting = true;

      try {
        this.promptErrorMessage = undefined;

        let locations = [];
        if (this.filters.countryCode) {
          const location = {
            countryCode: this.filters.countryCode
          };

          if (this.filters.location) {
            location.address = this.filters.location;
          }

          locations.push(location);
        }

        // console.log('this.filters.paid >>>>>', this.filters.paid);
        const body = {
          email: this.form.email,
          locations,
          jobPositionTypes: this.filters.positionTypes || [],
          jobRoles: this.filters.roles || [],
          paid:
            (this.filters.paid && this.filters.paid === 'true' && [this.filters.paid]) ||
            (this.filters.paid &&
              this.filters.paid[0] &&
              this.filters.paid[0] === 'true' &&
              this.filters.paid) ||
            [],
          subscribeDigest: true
        };

        if (this.applicantProfile && this.applicantProfile.email === body.email) {
          body.settingsKey = this.applicantProfile.settingsKey;
          await this.$store.dispatch('jobs/updateSubscription', body);
          this.show = false;
          this.showUpdateSuccess = true;
        } else {
          const subscribeResult = await this.$store.dispatch('jobs/subscribe', body);
          console.log('subscribeResult', subscribeResult);
          this.show = false;

          if (subscribeResult?.data.isNew) {
            this.showSuccess = true;
          } else {
            this.showUpdateSuccess = true;
          }

          this.$gtm.push({ event: 'fb', fbEvent: 'DigestSubmit' });
          this.$gtm.push({
            event: 'ga.event',
            category: 'quiz',
            action: 'DigestSubmit'
          });
        }
      } catch (err) {
        this.promptErrorMessage = err.message;
      }
      this.isRequesting = false;
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  }
};
</script>

<style lang="scss">
.newsletter-link {
  .btn {
    text-align: left;
  }

  img {
    padding-bottom: rem(6px);
    width: rem(21px);
    height: auto;
  }
}

.newsletter-modal {
  .modal-dialog .modal-content {
    padding-bottom: rem(45px);

    .modal-header {
      margin-bottom: 0;
    }
  }

  p {
    color: $gray-700;
    font-size: rem(18px);
    line-height: rem(27px);
  }

  a {
    display: inline-block;
    font-size: rem(18px);
  }
}
</style>
