<template>
  <div class="component-find-filter">
    <div :class="{ ['hidden-' + breakpoint + '-down']: !fullDisplay }">
      <slot name="label">
        <h5 class="component-find-filter__title" @click="toggle">
          {{ label }}
          <b-button v-if="collapsible" variant="link"
            ><i
              :class="[
                'icon',
                {
                  'icon-caret': isCollapsed,
                  'icon-caret-up': !isCollapsed
                }
              ]"
            ></i
          ></b-button>
        </h5>
      </slot>

      <b-form-group v-if="!isCollapsed" class="component-find-filter__inputs">
        <b-form-checkbox-group
          v-if="!singleSelection"
          v-model="model"
          stacked
          :class="{ 'component-find-filter__inputs--columns': horizontal }"
        >
          <div v-if="isLoading">
            <VueSkeletonLoader
              v-for="option in visibleOptions"
              :key="option[valueKey]"
              :width="180"
              :height="18"
              animation="fade"
              :rounded="true"
              style="margin-bottom: 0.5rem; cursor: unset"
            />
          </div>
          <div v-else>
            <b-form-checkbox
              v-for="(option, i) in visibleOptions"
              :key="option[valueKey]"
              :value="option[valueKey]"
            >
              <span v-if="addNumbers">
                {{ `${i + 1}.` }}
              </span>
              {{ option[labelKey] }}
              <span v-if="option.count" class="text-gray-500 fw400 fs12">({{ option.count }})</span>
            </b-form-checkbox>
          </div>
        </b-form-checkbox-group>
        <div v-else>
          <div v-if="radioButtons">
            <div v-if="isLoading">
              <VueSkeletonLoader
                v-for="option in visibleOptions"
                :key="option[valueKey]"
                :width="180"
                :height="18"
                animation="fade"
                :rounded="true"
                style="margin-bottom: 0.5rem; cursor: unset"
              />
            </div>
            <b-form-radio-group v-else v-model="model">
              <b-form-radio
                v-for="(option, i) in visibleOptions"
                :key="option[valueKey]"
                v-model="model"
                :checked="option[valueKey] === model"
                :value="option[valueKey]"
                @change="onSingleChange(option)"
              >
                <span v-if="addNumbers">
                  {{ `${i + 1}.` }}
                </span>
                {{ option[labelKey] }}
                <span v-if="option.count" class="text-gray-500 fw400 fs12"
                  >({{ option.count }})</span
                >
              </b-form-radio>
            </b-form-radio-group>
          </div>
          <div v-else>
            <div v-if="isLoading">
              <VueSkeletonLoader
                v-for="option in visibleOptions"
                :key="option[valueKey]"
                :width="180"
                :height="18"
                animation="fade"
                :rounded="true"
                style="margin-bottom: 0.5rem; cursor: unset"
              />
            </div>
            <b-form-checkbox-group v-model="model" v-else>
              <!-- option[valueKey] === model -->
              <!-- :checked="option[valueKey] == model ? '1' : '1'"
                :value="option[valueKey] == model ? '1' : '1'"
                v-model="model"
                @change="onSingleChange(option)"
                -->
              <b-form-checkbox
                v-for="(option, i) in visibleOptions"
                :key="option[valueKey]"
                :value="option[valueKey]"
                @change="onSingleChange(option)"
              >
                <span v-if="addNumbers">
                  {{ `${i + 1}.` }}
                </span>
                {{ option[labelKey] }}
                <span v-if="option.count" class="text-gray-500 fw400 fs12"
                  >({{ option.count }})</span
                >
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </div>

        <b-button
          v-if="!isCollapsed && displayLimit > 0 && hasMore"
          variant="link"
          class="mt-4 fs14"
          @click="isMoreExpanded = !isMoreExpanded"
        >
          {{ isMoreExpanded ? 'Less' : 'More' }}
          <i
            :class="[
              'icon',
              {
                'icon-caret': !isMoreExpanded,
                'icon-caret-up': isMoreExpanded
              }
            ]"
          ></i>
        </b-button>
      </b-form-group>
    </div>

    <div
      v-if="!fullDisplay"
      :class="{
        ['visible-' + breakpoint + '-down']: true
      }"
    >
      <button class="btn btn-sm btn-gray mr-4 mb-4" @click="$bvModal.show(modalId)">
        {{ label }}
      </button>

      <b-modal :id="modalId" centered :title="label" modal-class="modal-bb-squared" hide-footer>
        <template slot="default">
          <div v-if="isLoading">
            <VueSkeletonLoader
              v-for="option in options"
              :key="option[valueKey]"
              :width="180"
              :height="18"
              animation="fade"
              :rounded="true"
              style="margin-bottom: 0.5rem; cursor: unset"
            />
          </div>
          <b-form-group
            v-else
            v-model="model"
            class="
              component-find-filter__inputs
              component-find-filter__inputs--mobile
            "
          >
            <b-form-checkbox
              v-for="option in options"
              :key="option[valueKey]"
              v-model="model"
              :value="option[valueKey]"
            >
              {{ option[labelKey] }}
              <span v-if="option.count" class="text-gray-500 fw400 fs12">({{ option.count }})</span>
            </b-form-checkbox>
          </b-form-group>
          <button class="btn w-100 mt-24" @click="$bvModal.hide(modalId)">
            Apply Filter
          </button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'SearchFilter',
  components: {
    VueSkeletonLoader
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    breakpoint: {
      default: 'md'
    },
    singleSelection: Boolean,
    valueKey: {
      default: 'value'
    },
    labelKey: {
      default: 'label'
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      required: true
    },
    fullDisplay: {
      type: Boolean,
      default: false
    },
    radioButtons: {
      type: Boolean,
      default: false
    },
    horizontal: { type: Boolean, default: false },
    displayLimit: {
      type: Number,
      default: 11
    },
    collapsible: Boolean,
    collapsed: Boolean,
    addNumbers: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: this.collapsed && !this.value.length,
      isMoreExpanded: false
    };
  },
  computed: {
    modalId() {
      return 'modal-' + this._uid;
    },
    hasMore() {
      return this.options.length > this.displayLimit;
    },
    visibleOptions() {
      if (this.isCollapsed) {
        return [];
      }

      if (this.displayLimit > 0 && !this.isMoreExpanded) {
        return this.options.slice(0, this.displayLimit);
      }

      return this.options;
    },
    model: {
      get() {
        // console.log('this.value', typeof this.value);
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value(value) {
      if (this.isCollapsed && value.length) {
        this.isCollapsed = false;
      }
    }
  },
  methods: {
    onSingleChange(option) {
      this.model = this.value !== option[this.valueKey] ? option[this.valueKey] : null;
    },
    toggle() {
      if (this.collapsible) {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  }
};
</script>

<style lang="scss">
.component-find-filter {
  display: inline-flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &--desktop {
    display: block;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &--mobile {
    display: inline-block;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-bottom: 1rem;
    cursor: pointer;
  }

  &__inputs {
    .custom-radio {
      .custom-control-label {
        color: $gray-700;
        font-size: rem(14px);
        padding: rem(5px) 0 0 rem(5px);
        margin-bottom: rem(7px);

        &:before {
          box-shadow: none !important;
        }
      }

      .custom-control-input:checked ~ .custom-control-label {
        color: $gray-700;
        font-weight: normal;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $blue-800;
        background-color: $blue-800;
      }
    }

    .custom-checkbox {
      .custom-control-label {
        color: $gray-700;
        font-size: rem(14px);
        padding: rem(2px) 0 0 rem(5px);
        margin-bottom: rem(7px);

        &:before {
          box-shadow: none !important;
        }
      }

      .custom-control-input:checked ~ .custom-control-label {
        color: $gray-900;
        font-weight: 500;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $blue-800;
        background-color: $blue-800;
      }
    }

    &--mobile {
      padding-top: rem(12px);

      .custom-checkbox {
        .custom-control-label {
          font-size: rem(16px);
        }
      }
    }

    &--columns {
      .custom-checkbox {
        min-width: rem(300px);

        .custom-control-input:checked ~ .custom-control-label {
          color: $gray-700;
          font-weight: normal;
        }
      }

      @include media-breakpoint-up(lg) {
        -webkit-column-count: 3; /* Chrome, Safari, Opera */
        -moz-column-count: 3; /* Firefox */
        column-count: 3;
      }
      @include media-breakpoint-down(md) {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
      }

      @include media-breakpoint-down(sm) {
        -webkit-column-count: 1; /* Chrome, Safari, Opera */
        -moz-column-count: 1; /* Firefox */
        column-count: 1;
      }
    }
  }
}
</style>
