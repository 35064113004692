<template>
  <div class="card">
    <router-link v-if="link && !linkExternal" :to="link"></router-link>
    <a v-if="link && linkExternal" :href="link" target="_blank"></a>

    <div v-if="$slots.header" class="card__header">
      <slot name="header"></slot>
    </div>

    <div class="card__content">
      <slot />
    </div>

    <div v-if="$slots.footer" class="card__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    link: String,
    linkExternal: Boolean
  }
}
</script>

<style lang="scss">
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $gray-900;
  padding: rem(30px);

  @include transition(0.2s);

  @include media-breakpoint-down(lg) {
    padding: rem(25px);
  }

  @include media-breakpoint-down(sm) {
    padding: rem(20px);
    // height: rem(280px);
    height: auto;
  }

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__header {
    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 0 auto;
  }

  &:hover,
  &:active,
  &:focus {
    transform: translateY(-1px);
    box-shadow: $box-shadow-medium;
  }
}
</style>
