<template>
  <div class="no-results pt-20" :class="{ 'has-border': border }">
    <h4 class="pb-10">No search results</h4>
    <p>
      Change your search criteria
      <i class="icon-search"></i>
      <span v-if="type !== 'investors' && type !== 'industries'"
        >or location <i class="icon-location"></i
      ></span>
      to&nbsp;find matching {{ type }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SearchNoResults',
  props: {
    type: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.no-results {
  text-align: center;
  padding: 0 rem(45px) rem(30px);

  @include media-breakpoint-down(sm) {
    padding: 0 0 rem(30px);
  }

  h4 {
    font-size: rem(24px);
  }

  &.has-border p {
    border-bottom: 1px solid #d9d9d9;
  }

  p {
    font-size: rem(18px);
    color: #51596c;
    padding-bottom: rem(35px);

    i {
      display: inline-block;
      vertical-align: middle;
      padding: 0 rem(6px);
      font-size: rem(24px);
    }

    @include media-breakpoint-down(sm) {
      font-size: rem(16px);

      i {
        font-size: rem(20px);
      }
    }
  }
}
</style>
