<template>
  <b-dropdown toggle-class="custom-dropdown" no-caret right>
    <template slot="button-content">
      <span>{{ selected.label }}</span>
      <i class="icon-caret text-blue-900"></i>
    </template>
    <b-dropdown-item
      v-for="(option, key) in options"
      :key="key"
      @click="$emit('change', key)"
    >
      {{ option.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    options: {
      type: Object
    },
    selected: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.custom-dropdown {
  background: white !important;
  border: 1px solid rgba($gray-900, 0.2) !important;
  color: $gray-900 !important;
  font-weight: 400 !important;
  padding: 0 1em;
  width: rem(170px);
  justify-content: space-between !important;
  + .dropdown-menu {
    min-width: 100%;
  }
}
</style>
