<template>
  <div class="component-pagination">
    <!-- <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="center" hide-goto-end-buttons></b-pagination> -->
    <b-pagination-nav
      v-model="model"
      :link-gen="linkGen"
      :number-of-pages="numberOfPages"
      align="center"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchPagination',
  props: {
    result: Object,
    pages: Number,
    value: Number
  },
  computed: {
    numberOfPages() {
      return this.result ? this.result.pages : this.pages || 1
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    linkGen(pageNum) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum === 1 ? undefined : pageNum
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
