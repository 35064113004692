<template>
  <div
    class="media-item"
    :style="{ alignItems: valign, flexDirection: halign }"
  >
    <router-link v-if="routerLink" :to="routerLink">
      <div
        class="media-item__image"
        :class="[
          'media-item__image--image--' + imageSize,
          'media-item__image--gap--' + gapSize
        ]"
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </router-link>

    <div
      v-else
      class="media-item__image"
      :class="[
        'media-item__image--image--' + imageSize,
        'media-item__image--gap--' + gapSize
      ]"
      :style="{ backgroundImage: 'url(' + src + ')' }"
    ></div>

    <div class="media-item__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaItem',
  props: {
    src: {
      type: String,
      required: true
    },
    imageSize: {
      type: String,
      default: 'default'
    },
    gapSize: {
      type: String,
      default: 'default'
    },
    valign: {
      type: String,
      default: 'flex-start'
    },
    halign: {
      type: String,
      default: 'row'
    },
    routerLink: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.media-item {
  display: flex;

  &__image {
    flex: 0 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: rem(40px);
    height: rem(40px);
    min-width: rem(40px);
    margin-right: rem(10px);

    &--image--small {
      width: rem(48px);
      height: rem(48px);
      min-width: rem(48px);
    }

    &--image--medium {
      width: rem(64px);
      height: rem(64px);
      min-width: rem(64px);
    }

    &--image--mediumbig {
      width: rem(100px);
      height: rem((100px));
      min-width: rem((100px));
    }

    &--image--big {
      width: rem(135px);
      height: rem(135px);
      min-width: rem(135px);
    }

    &--gap--small {
      margin-right: 0;
    }

    &--gap--medium {
      margin-right: rem(16px);
    }

    &--gap--big {
      margin-right: rem(24px);
    }
  }

  &__content {
    flex: 1 1 auto;

    h2 {
      word-wrap: break-word;
    }
  }
}
</style>
