<template>
  <Card
    :class="{
      'card-job-find-list': true,
      bordered: bordered,
      compact: compact,
      'card-job-is-featured': isFeatured
    }"
  >
    <template>
      <img
        v-if="isFeatured"
        class="card-job-is-featured__ribbon"
        src="~/assets/components/jobs/featuredRibbon.svg"
        alt="Featured job"
      />
      <MediaItem
        v-if="job.company"
        :src="job.company.logoImage.thumbnailSizeUrl"
        image-size="small"
        gap-size="medium"
      >
        <div class="card-job-find-list__title">
          <span class="card-job-find-list__position">{{ job.title }}</span>
          <BulletInlineList :size="13" class="text-gray-600 fw500">
            <span>{{ job.company.name }}</span>
            <span v-if="job.location && !job.isRemote">{{ job.location.shortName }}</span>
            <span v-if="job.isRemote">Remote</span>
            <span v-for="type in job.jobPositionTypes" :key="type">{{
              jobPositionTypeLabelById(type)
            }}</span>
          </BulletInlineList>
        </div>
        <div v-if="appliedDate" class="card-job-find-list__date">
          <span>{{ job.interestedAt | date('DD.M.YY') }}</span>
        </div>
        <div
          v-if="status"
          class="card-job-find-list__status"
          :class="['status-' + job.myJobStatus]"
        >
          <span>{{ job.myJobStatusLabel }}</span>
        </div>
        <div
          v-if="user && user.isAdmin && !compact && job.views && job.views.week > 0"
          class="card-job-find-list__views text-gray-600"
        >
          {{ job.formattedViews ? job.formattedViews.week : '' }}
        </div>
        <div v-if="!compact && !isCareersPage" class="card-job-find-list__tag">
          <JobsSave :job="job" @toggle="toggleSave" />
        </div>
      </MediaItem>
    </template>

    <router-link
      :to="isPreview ? `${job.url}?careerPagePreview=true` : job.url"
      class="card-job-find-list__link"
    ></router-link>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import MediaItem from '@/components/MediaItem';
import BulletInlineList from '@/components/BulletInlineList';
import JobsSave from './JobsSave';
import get from 'lodash/get';

export default {
  name: 'JobsListItem',
  components: {
    Card,
    MediaItem,
    BulletInlineList,
    JobsSave
  },
  props: {
    job: {
      type: Object,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    isCareersPage: {
      type: Boolean,
      default: false
    },
    appliedDate: Boolean,
    status: Boolean,
    isFeatured: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.data;
    },
    isPreview() {
      return get(this.$route, 'query.careerPagePreview', false) ? true : false;
    },
    saved: {
      get() {
        return this.job.saved;
      },
      set(value) {
        this.$emit('save', this.job, value);
      }
    }
  },
  methods: {
    jobPositionTypeLabelById(id) {
      const type = this.$store.getters['meta/jobPositionTypeById'](id);
      return type && type.label;
    },
    async toggleSave(value) {
      try {
        await this.$store.dispatch('jobs/save', {
          job: this.job,
          value
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style lang="scss">
.card-job-is-featured {
  position: relative;
  border: 3px solid $blue-800;
  margin-top: rem(24px);

  &__ribbon {
    position: absolute;
    right: rem(-19px);
    top: rem(-17px);
  }
}

.card-job-find-list {
  padding: rem(24px);
  height: auto;
  @include transition(0.03s);

  &:hover,
  &:active,
  &:focus {
    transform: translateY(0);
    box-shadow: $box-shadow-lighter;

    .card-job-find-list__position {
      text-decoration: none;
      color: $blue-900;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .media-item {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start !important;
    }

    &__logo {
      @include media-breakpoint-down(sm) {
        margin-bottom: rem(16px);
      }
    }

    &__content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__title {
    padding-right: rem(20px);

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &__date {
    min-width: 120px;
  }

  &__status {
    height: rem(40px);
    line-height: rem(40px);
    padding: 0 1rem;
    font-weight: 500;
    font-size: 1rem;
    background-color: $gray-100;
    color: $gray-900;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    min-width: 120px;
    margin-right: rem(30px);

    > span {
      display: inline-block;
      overflow: hidden;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      font-size: rem(13px);
      padding: 0 rem(4px);
      height: rem(30px);
      line-height: rem(30px);

      > span {
        display: inline-block;
      }
    }

    &.status-active {
      background-color: rgba(68, 215, 183, 0.33);
      color: #4f6964;
    }

    &.status-saved {
      background-color: rgba($deep-blue, 0.1);
      color: $blue-800;
    }
  }

  &__views {
    color: $gray-900;
    font-weight: 500;
    font-size: rem(13px);
    margin-left: auto;
    margin-right: rem(30px);
  }

  &__tag {
    text-align: right;

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      padding-top: rem(16px);
    }
  }

  &__position {
    display: block;
    font-size: rem(20px);
    font-weight: 500;
    letter-spacing: rem(-0.5px);
    line-height: 1.4;
    color: $gray-900;
    overflow: hidden;
    text-overflow: ellipsis;
    @include transition(0.1s);

    @include media-breakpoint-down(md) {
      font-size: rem(18px);
      max-height: rem(19px);
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.2;
      -webkit-line-clamp: 3;
      max-height: rem(63px);
      margin-top: 0;
    }
  }

  &__published {
    font-size: rem(13px);
    font-weight: 500;
    color: $gray-600;
    white-space: nowrap;
    margin-bottom: rem(10px);

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }

  &__saved {
    position: relative;
    z-index: 3;

    .btn {
      height: auto;
      width: auto;
      padding: 0;
      margin: 0;
      line-height: 1;
      background-color: transparent !important;
      border: none !important;
      margin-bottom: rem(-12px);

      input {
        display: none;
      }

      img {
        cursor: pointer;
        height: 32px;
      }
    }
  }

  &.bordered {
    border: 1px solid #caccd2;
  }

  &.compact {
    .card-job-find-list__position {
      font-size: rem(16px) !important;
    }
  }
}
</style>
